import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { type SxProps } from '@mui/material'
import type { RichInvoicePaymentStatus } from '@orus.eu/backend/src/invoicing/routers/invoices'
import type { InvoicePaymentStatus } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import type { PaymentMethod } from '@orus.eu/backend/src/views/payment-view'
import { getZonedDateTimeFromMillis } from '@orus.eu/calendar-date'
import {
  Avatar,
  colorTokens,
  ContentContainerAppClient,
  PageTitle,
  PersistentNotification,
  problemColor,
  spacing,
  Text,
  useTranslate,
} from '@orus.eu/pharaoh'
import { DateTime } from 'luxon'
import { memo, useMemo, type FunctionComponent, type ReactElement } from 'react'
import { trpcReact } from '../../../client'
import { useShowIllustrations } from '../../../lib/use-show-illustrations'
import { RouterLinkButton } from '../../atoms/router-link-button'
import { ContainedIcon } from '../../molecules/contained-icon'
import { InvoicingEmailCheckbox } from '../../organisms/invoicing-email-checkbox'
import { SmallScreenMargin } from '../../templates/small-screen-margin'
import { InvoiceList } from './invoice-list'
import { NextInvoiceElement } from './next-invoice-element'

export default function InvoicesPage(): ReactElement {
  const [invoices] = trpcReact.invoices.listMyInvoices.useSuspenseQuery()
  const [paymentMethod] = trpcReact.paymentMethod.getMyPaymentMethod.useSuspenseQuery()

  // Sort once and for all here : all invoices are sorted by dueTimestamp
  invoices.sort((invoice) => invoice.invoice.dueTimestamp)

  return <InvoicesPageContent invoices={invoices} paymentMethod={paymentMethod} />
}

const InvoicesPageContent = memo<{
  invoices: RichInvoicePaymentStatus[]
  paymentMethod: PaymentMethod | null
}>(function InvoicesPageContent({ invoices, paymentMethod }): ReactElement {
  const translate = useTranslate()
  const newInvoices = useMemo(() => invoices.filter((invoice) => invoice.paymentStatus === 'new'), [invoices])
  const nextInvoice = [...newInvoices].sort((invoice) => invoice.invoice.issueTimestamp)[0]
  const hasNextInvoice = typeof nextInvoice !== 'undefined'
  const receipts = useMemo(() => invoices.filter((invoice) => invoice.receiptDocumentAvailable), [invoices])

  const unpaidInvoices = useMemo(
    () =>
      invoices.filter(
        (invoice) =>
          invoice.invoiceDocumentAvailable &&
          (invoice.paymentStatus === 'failed' || invoice.paymentStatus == 'disputed'),
      ),
    [invoices],
  )
  const invoicesWithPendingPayment = useMemo(
    () => invoices.filter((invoice) => invoice.invoiceDocumentAvailable && invoice.paymentStatus === 'pending'),
    [invoices],
  )
  const isEmptyState =
    !hasNextInvoice && receipts.length === 0 && unpaidInvoices.length === 0 && invoicesWithPendingPayment.length === 0

  return (
    <ContentContainerAppClient gap="0">
      <PageTitle title="Mes factures" fullWidth hideOnDesktop />
      {isEmptyState ? (
        <NoDocumentsState />
      ) : (
        <>
          {unpaidInvoices.length > 0 ? <UnpaidInvoiceBanner invoice={unpaidInvoices[0]} /> : <></>}
          {hasNextInvoice ? (
            <NextInvoiceElement invoicePaymentStatus={nextInvoice} paymentMethod={paymentMethod} />
          ) : (
            <></>
          )}
          <CheckboxWrapper>
            <InvoicingEmailCheckbox />
          </CheckboxWrapper>
          {invoicesWithPendingPayment.length > 0 ? (
            <InvoiceList
              variant="pending"
              invoices={invoicesWithPendingPayment}
              title={translate('invoices_pending_payment')}
            />
          ) : (
            <></>
          )}
          {unpaidInvoices.length > 0 ? (
            <InvoiceList variant="unpaid" invoices={unpaidInvoices} title={translate('invoices_unpaid')} />
          ) : (
            <></>
          )}
          {receipts.length > 0 ? (
            <InvoiceList variant="receipt" invoices={receipts} title={translate('invoices_history')} />
          ) : (
            <></>
          )}
        </>
      )}
    </ContentContainerAppClient>
  )
})

function NoDocumentsState(): ReactElement {
  const translate = useTranslate()
  const showIllustrations = useShowIllustrations()

  return (
    <SmallScreenMargin>
      <div
        css={css`
          margin-top: ${spacing[60]};
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {showIllustrations ? (
          <Avatar illustration="box_big" size="80" />
        ) : (
          <ContainedIcon
            icon="receipt-regular"
            color={colorTokens['color-bg-info-primary']}
            backgroundColor={colorTokens['color-bg-info-tertiary']}
            size="60"
          />
        )}
      </div>
      <div
        css={css`
          margin-top: ${spacing[60]};
          display: flex;
          gap: ${spacing[40]};
          flex-direction: column;
        `}
      >
        <Text variant="h2">{translate('no_invoices')}</Text>
        <div>
          <Text variant="body2">{translate('no_invoice_explanation')}</Text>
          <br />
          <br />
          <Text variant="body2">{translate('no_invoice_reassurance')}</Text>
        </div>
      </div>
    </SmallScreenMargin>
  )
}

const UnpaidInvoiceBanner: FunctionComponent<{ invoice: InvoicePaymentStatus }> = memo(function UnpaidInvoiceBanner({
  invoice,
}): ReactElement {
  const dueDateString = getZonedDateTimeFromMillis(invoice.invoice.dueTimestamp)
    .setLocale('fr')
    .toLocaleString(DateTime.DATE_FULL)

  const routerLinkButtonSx: SxProps = {
    padding: `${spacing[30]} 0px 0px 0px`,
    color: problemColor,
  } as const

  return (
    <SmallScreenMargin>
      <PersistentNotification
        variant="danger"
        css={css`
          margin-top: ${spacing[60]};
        `}
      >
        Nous n’avons pas encore reçu de paiement pour votre facture du <b>{dueDateString}</b>. Nous vous invitons à
        mettre à jour vos informations de paiement.
        <RouterLinkButton to="/payment-methods/new" sx={routerLinkButtonSx}>
          Modifier mon moyen de paiement
        </RouterLinkButton>
      </PersistentNotification>
    </SmallScreenMargin>
  )
})

const CheckboxWrapper = styled.div`
  padding: ${spacing[60]};
`
