import { Box, Card, css } from '@mui/material'
import type { InvoicePaymentStatus } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import type { PaymentMethod } from '@orus.eu/backend/src/views/payment-view'
import { PARIS, calendarDateToString, getCalendarDateFromTimestamp } from '@orus.eu/calendar-date'
import { AmountText, Icon, Spinner, Text, primaryColor, spacing, useAsyncCallback, useLanguage } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { dynamicFileNames } from '../../../lib/download-filenames'
import { useDownloadInvoices } from '../../../lib/hooks/use-download-invoices'
import { UnstyledButton } from '../../atoms/unstyled-button'
import { LegacyPaymentMethodCardBody } from '../../molecules/card-payment-methods'
import { SmallScreenMargin } from '../../templates/small-screen-margin'

type NextInvoiceProps = {
  invoicePaymentStatus: InvoicePaymentStatus
  paymentMethod: PaymentMethod | null
}

export const NextInvoiceElement = memo<NextInvoiceProps>(function NextInvoiceElement({
  invoicePaymentStatus,
  paymentMethod,
}) {
  const language = useLanguage()
  const invoiceAmount = invoicePaymentStatus.invoice.totalPremium
  const invoiceDueDate = getCalendarDateFromTimestamp(invoicePaymentStatus.invoice.dueTimestamp, PARIS)
  const { invoiceId } = invoicePaymentStatus.invoice
  const { download, downloadInProgress } = useDownloadInvoices({ invoiceId })

  const handleDownload = useAsyncCallback(async () => {
    download('downloadMyInvoice', dynamicFileNames.invoice(invoiceId, language))
  }, [download, invoiceId, language])

  return (
    <>
      <SmallScreenMargin>
        <Card sx={{ marginTop: spacing[60], gap: spacing[30], marginBottom: spacing[80] }}>
          <UnstyledButton onClick={handleDownload} disabled={downloadInProgress} fullWidth>
            <Box
              display="flex"
              flexDirection="column"
              gap={spacing[30]}
              alignItems="stretch"
              textAlign="center"
              padding={spacing[70]}
              width="100%"
            >
              <Text variant="captionMedium">PROCHAINE ÉCHÉANCE</Text>
              <Text
                css={css`
                  position: relative;
                `}
              >
                <AmountText amount={invoiceAmount} displayDecimals />
                <Text
                  css={css`
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    padding: ${spacing[30]};
                  `}
                  variant="body1"
                >
                  {downloadInProgress ? (
                    <Spinner size="30" />
                  ) : (
                    <Icon icon="arrow-down-to-line-regular" color={primaryColor} />
                  )}
                </Text>
              </Text>
              <Box>
                <Text variant="body2Medium">Prélévement le </Text>
                <Text variant="body2Medium">{calendarDateToString(invoiceDueDate, PARIS, 'DATE_FULL', language)}</Text>
              </Box>
            </Box>
          </UnstyledButton>
          <LegacyPaymentMethodCardBody paymentMethod={paymentMethod} />
        </Card>
      </SmallScreenMargin>
    </>
  )
})
